<template>
  <div id="root">
    <ais-instant-search
      :search-client="searchClient"
      index-name="instant_search"
      :routing="routing"
    >
      <ais-configure
        :attributesToSnippet="['bio:10']"
        snippetEllipsisText="…"
        removeWordsIfNoResults="allOptional"
      />

      <header class="header" id="header">

        <p class="header-title">The A-Z of Aberdeen’s lifestyle businesses</p>

        <ais-search-box
          placeholder="Enter a search term"
        >
          <template slot="submit-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="16"
              height="16"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11"></circle>
                <path d="M16 16l-3.87-3.87"></path>
              </g>
            </svg>
          </template>
        </ais-search-box>
      </header>

      <main class="search__container">
        <div class="container-wrapper">
          <section class="container-filters">
            <div class="container-header">
              <h2>Filters</h2>

              <ais-clear-refinements data-layout="desktop">
                <template slot="resetLabel">
                  <div class="clear-filters">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 11 11"
                    >
                      <g fill="none" fill-rule="evenodd" opacity=".4">
                        <path d="M0 0h11v11H0z"></path>
                        <path
                          fill="#000"
                          fill-rule="nonzero"
                          d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                        ></path>
                      </g>
                    </svg>
                    Clear filters
                  </div>
                </template>
              </ais-clear-refinements>

              <ais-stats data-layout="mobile">
                <template slot="default" slot-scope="{ nbHits }">
                  <span class="ais-Stats-text">
                    <strong>{{ formatNumber(nbHits) }}</strong> results
                  </span>
                </template>
              </ais-stats>
            </div>

            <div class="container-body">
              <ais-sort-by
                class="container-option container-sort"
                :items="[
                  { value: 'instant_search', label: 'Most relevant' },
                  { value: 'instant_search_newest', label: 'Newest' },
                ]"
              />
              <ais-panel>
                <template slot="header">Category</template>

                <template slot="default">
                  <ais-hierarchical-menu
                    :attributes="[
                      'hierarchicalCategories.lvl0'
                    ]"
                  />
                </template>
              </ais-panel>

              <ais-panel>
                <template slot="header">Tags</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="tags"
                    searchable
                    searchablePlaceholder="Search for tags…"
                    show-more
                  />
                </template>
              </ais-panel>



            </div>
          </section>

          <footer class="container-filters-footer" data-layout="mobile">
            <clear-refinements
              class="container-filters-footer-button-wrapper"
              @click="closeFilters"
            />

            <ais-stats
              class="container-filters-footer-button-wrapper"
            >
              <template slot="default" slot-scope="{ nbHits }">
                <button class="button button-primary" @click="closeFilters">
                  See {{ formatNumber(nbHits) }} results
                </button>
              </template>
            </ais-stats>
          </footer>
        </div>

        <section class="container-results">
          <header class="container-header container-options">
              <ais-sort-by
                class="container-option"
                :items="[
                  { value: 'instant_search', label: 'Most relevant' },
                  { value: 'instant_search_newest', label: 'Newest' },
                ]"
              />

            <ais-hits-per-page
              class="container-option"
              :items="[
                {
                  label: '30 results per page',
                  value: 30,
                  default: getSelectedHitsPerPageValue() === 30 || !getSelectedHitsPerPageValue(),
                },
                {
                  label: '60 results per page',
                  value: 60,
                  default: getSelectedHitsPerPageValue() === 60,
                },
                {
                  label: '90 results per page',
                  value: 90,
                  default: getSelectedHitsPerPageValue() === 90,
                },
              ]"
            />
          </header>

          <ais-hits>
            <div slot="item" slot-scope="{ item }">
              <article class="hit card">

                <div class="hit-info-container card-body">

                  <div class="card__image_profile">
                    <a :href="item.url">
                    <img :src="item.image" :alt="item.name" class="img-thumbnail"/>
                    </a>
                  </div>
                  <div class="card__image_content">
                    <h5 class="card-title"><a :href="item.url">
                      <ais-highlight attribute="name" :hit="item"/>
                    </a></h5>
                    <a :href="item.url">
                    <img :src="item.image" :alt="item.name" class="img-thumbnail"/>
                    </a>

                    <p class="mt-2 mb-0 card__bio">
                      <a :href="item.url">
                      <ais-snippet attribute="bio" :hit="item"/>

                    </a>
                    </p>

                  <footer>
                    <a class="btn btn-link px-0" :href="item.url">View</a>

                  </footer>
                  </div>
                </div>
              </article>
            </div>
          </ais-hits>

          <no-results/>

          <footer class="container-footer">
            <ais-pagination :padding="2" v-on:page-change="scrollUp">
              <div
                slot-scope="{
                  currentRefinement,
                  nbPages,
                  pages,
                  isFirstPage,
                  isLastPage,
                  refine,
                  createURL
                }"
                class="ais-Pagination"
              >
                <ul class="ais-Pagination-list">
                  <li
                    class="ais-Pagination-item ais-Pagination-item--previousPage ais-Pagination-item--disabled"
                    v-if="isFirstPage"
                  >
                    <span class="ais-Pagination-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M9 5H1M5 9L1 5l4-4"></path>
                        </g>
                      </svg>
                    </span>
                  </li>

                  <li
                    class="ais-Pagination-item ais-Pagination-item--previousPage"
                    v-if="!isFirstPage"
                  >
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(currentRefinement - 1)"
                      @click.prevent="refine(currentRefinement - 1)"
                      aria-label="Previous"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M9 5H1M5 9L1 5l4-4"></path>
                        </g>
                      </svg>
                    </a>
                  </li>

                  <li
                    :class="cx('ais-Pagination-item', 'ais-Pagination-item--page', {
                      'ais-Pagination-item--selected': page === currentRefinement
                    })"
                    v-for="page in pages"
                    :key="page"
                  >
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(page)"
                      :style="{ fontWeight: page === currentRefinement ? 'bold' : '' }"
                      @click.prevent="refine(page)"
                    >{{ page + 1 }}</a>
                  </li>

                  <li class="ais-Pagination-item ais-Pagination-item--nextPage" v-if="!isLastPage">
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(currentRefinement + 1)"
                      @click.prevent="refine(currentRefinement + 1)"
                      aria-label="Next"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M1 5h8M5 9l4-4-4-4"></path>
                        </g>
                      </svg>
                    </a>
                  </li>

                  <li
                    class="ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled"
                    v-if="isLastPage"
                  >
                    <span class="ais-Pagination-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M1 5h8M5 9l4-4-4-4"></path>
                        </g>
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </ais-pagination>
          </footer>
        </section>

        <aside data-layout="mobile">
          <button class="filters-button" @click="openFilters">
            <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 14">
              <path
                d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
                stroke="#fff"
                stroke-width="1.29"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Filters
          </button>
        </aside>
      </main>

    </ais-instant-search>
  </div>
</template>

<script>
  import algoliasearch from 'algoliasearch/lite';
  import VueSlider from 'vue-slider-component';
  import cx from 'classnames';
  import ClearRefinements from './widgets/ClearRefinements.vue';
  import NoResults from './widgets/NoResults.vue';
  import {formatNumber} from './utils';
  import routing from './routing';

  import './Theme.css';
  import './App.css';
  import './App.mobile.css';
  import './widgets/PriceSlider.css';

  export default {
    components: {
      VueSlider,
      ClearRefinements,
      NoResults
    },
    created() {
      this.onKeyUp = event => {
        if (event.key !== 'Escape') {
          return;
        }
        this.closeFilters();
      }

      this.onClick = event => {
        if (event.target !== this.header) {
          return;
        }

        this.closeFilters();
      }
    },
    mounted() {
      this.resultsContainer = document.querySelector('.container-results');
      this.header = document.querySelector('#header');
    },
    data() {
      return {
        cx,
        searchClient: algoliasearch(
          '69MX9BWNKK',
          'fa9cb942367f29587cc8661d3f8f4f26'
        ),
        routing
      };
    },
    methods: {
      formatNumber,
      toValue(value, range) {
        return [
          value.min !== null ? value.min : range.min,
          value.max !== null ? value.max : range.max,
        ];
      },
      getSelectedHitsPerPageValue() {
        const [, hitsPerPage] = document.location.search.match(/hitsPerPage=([0-9]+)/) || [];
        return Number(hitsPerPage);
      },
      scrollUp () {
        window.scrollTo(0, 0);
      },
      openFilters() {
        document.body.classList.add('filtering');
        var abzContent = document.getElementsByClassName("filter--hide"); //divsToHide is an array
        for(var i = 0; i < abzContent.length; i++){
            abzContent[i].style.display = "none";
        }
        window.scrollTo(0, 0);
        window.addEventListener('keyup', this.onKeyUp);
        window.addEventListener('click', this.onClick);
      },
      closeFilters() {
        document.body.classList.remove('filtering');
        var abzContent = document.getElementsByClassName("filter--hide"); //divsToHide is an array
        for(var i = 0; i < abzContent.length; i++){
            abzContent[i].style.display = "block";
        }
        this.resultsContainer.scrollIntoView();
        window.removeEventListener('keyup', this.onKeyUp);
        window.removeEventListener('click', this.onClick);
      },
    },
  };
</script>
